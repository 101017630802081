import React, { useState, useEffect } from 'react';
import './App.css';


export default function App() {
  const [currentAlbum, setCurrentAlbum] = useState('beauty');
  const [currentFolder, setCurrentFolder] = useState('beauty');
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(1);
  const [selectedAlbumIdx, setSelectedAlbumIdx] = useState(null); // Changed initial state to null
  const [indexLim, setIndexLim] = useState(34);
  const [expandedCategory, setExpandedCategory] = useState(null); // Changed initial state to null
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showAbout, setShowAbout] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState(null); // State to track the selected nav item
  const [albumData, setAlbumData] = useState([
    { category: "beauty", albumName: "all", photosCount: 34, folder: "beauty" },

    { category: "fashion", albumName: "Shades of Red", photosCount: 20, folder: "fashion/Shades of Red" },
    { category: "fashion", albumName: "Roma Narsinghani", photosCount: 12, folder: "fashion/Roma Narsinghani" },
    { category: "fashion", albumName: "Reistor", photosCount: 14, folder: "fashion/Reistor" },
    { category: "fashion", albumName: "Rara Avis I", photosCount: 10, folder: "fashion/Rara Avis I" },
    { category: "fashion", albumName: "Rara Avis II", photosCount: 7, folder: "fashion/Rara Avis II" },
    { category: "fashion", albumName: "Rara Avis III", photosCount: 13, folder: "fashion/Rara Avis III" },
    { category: "fashion", albumName: "Confluence x Swarovski", photosCount: 12, folder: "fashion/Confluence x Swarovski" },
    { category: "fashion", albumName: "Lagom", photosCount: 11, folder: "fashion/Lagom" },
    { category: "fashion", albumName: "Little Things Studio", photosCount: 10, folder: "fashion/Little Things Studio" },
   
    
    

    { category: "product", albumName: "all", photosCount: 84, folder: "product" },
    { category: "personal", albumName: "all", photosCount: 29, folder: "personal" },
   
  ]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  const updateIndex = () => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex < indexLim ? prevIndex + 1 : 1));
  };

  const updateAlbum = (album, count, folder, albumIndex) => {
    setCurrentAlbum(album);
    setCurrentPhotoIndex(1);
    setIndexLim(count);
    setCurrentFolder(folder);
    setSelectedAlbumIdx(albumIndex);
    setShowAbout(false);
    setIsMenuOpen(false);
    setSelectedNavItem(null); // Clear the selected nav item
  };

  const toggleCategory = (category) => {
    // Always set the expanded category to the new category, collapsing any previously expanded one
    setExpandedCategory(prevCategory => (prevCategory === category ? null : category));
    setSelectedAlbumIdx(null); // Clear selected album when category changes
    setSelectedNavItem(null);  // Clear the selected nav item when category changes
  };
  
  

  const toggleMenu = () => {
    setIsMenuOpen((prevStatus) => !prevStatus);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const showAboutSection = () => {
    setShowAbout(true);
    setExpandedCategory(null);
    setSelectedAlbumIdx(null);
    setIsMenuOpen(false);
    setSelectedNavItem('about'); // Set the selected nav item to 'about'
  };

  const uniqueCategories = [...new Set(albumData.map(album => album.category))];
  
  return (
    <>
      <div className="nav">
        <div className="logo">
          <a href="https://aasthamanchanda.com"><img src="../media/logo.png" alt="Logo" /></a>
        </div>
        <div className={`hamburger ${isMenuOpen ? 'hidden' : ''}`} onClick={toggleMenu}>
          ☰
        </div>
        <ul className={isMenuOpen ? 'nav-menu open' : 'nav-menu'}>
          <div className="close" onClick={closeMenu}>✕</div>
          {uniqueCategories.map((category, categoryIndex) => {
  const filteredAlbums = albumData.filter(album => album.category === category && album.albumName !== 'all');
  const allAlbum = albumData.find(album => album.category === category && album.albumName === 'all');

  return (
    <li key={categoryIndex}>
      <a
        href="#"
        onClick={(event) => {
          event.preventDefault();
          if (allAlbum) {
            // Set the selectedAlbumIdx for the "all" album and update the currentAlbum details
            updateAlbum(allAlbum.albumName, allAlbum.photosCount, allAlbum.folder, albumData.indexOf(allAlbum));
          } else {
            // Toggle the category for albums with subalbums
            toggleCategory(category);
          }
        }}
        // Apply selected class if the current selected album belongs to the current category
        className={selectedAlbumIdx !== null && albumData[selectedAlbumIdx].category === category ? 'selected' : ''}
      >
        {/* Only show chevrons if there are subalbums */}
         {category}
      </a>

      {/* Render subalbums only if there are any */}
      {filteredAlbums.length > 0 && (
        <ul className={`sub-menu ${expandedCategory === category ? 'expanded' : 'collapsed'}`}>
          {filteredAlbums.map((album, albumIndex) => (
            <li key={albumIndex} className={selectedAlbumIdx === albumData.indexOf(album) ? 'selected-album' : ''}>
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  updateAlbum(album.albumName, album.photosCount, album.folder, albumData.indexOf(album));
                }}
                className={selectedAlbumIdx === albumData.indexOf(album) ? 'selected-album' : ''}
              >
                {album.albumName}
              </a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
})}



          <hr className="divider" />
          <li class="small">
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                showAboutSection();
              }}
              className={selectedNavItem === 'about' ? 'selected' : ''}
            >
              about
            </a>
          </li>
          <li class="small"><a href="mailto:info.manchandaa@gmail.com">info.manchandaa@gmail.com 📧</a></li>
          <li class="small"><a href="https://instagram.com/manchandaa" target="_blank" rel="noopener noreferrer">instagram ↗</a></li>
        </ul>
      </div>
      <div className="image-container">
        {showAbout ? (
          <div className="about">
            <h2>About the Photographer</h2>
            <p>Aastha Manchanda is a photographer and visual artist based in India, primarily focused on beauty, fashion, and still-life photography.</p>
          </div>
        ) : (
          isMobile ? (
            [...Array(indexLim)].map((_, index) => (
              <img
                key={index}
                src={`../media/${currentFolder}/${index + 1}.jpg`}
                alt={`Album Photo ${index + 1}`}
              />
            ))
          ) : (
            <img
              src={`../media/${currentFolder}/${currentPhotoIndex}.jpg`}
              alt="Displayed Album Photo"
              onClick={updateIndex}
            />
          )
        )}
      </div>
    </>
  );
}
